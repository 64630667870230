import React from 'react';
import {BrowserRouter, Route,Switch} from 'react-router-dom';

import Home from './home';
import KOLidentification from './kolidentification';
import KOLEngagement from './kolEngagement';
import About from './about';
import Pricing from './pricing';
import Contact from './contact';
import Resources from './blogs';
import BlogsDetails from './blogdetails';
// import CaseStudyDetails from './casestudydetails';
// import CaseStudy from './casestudy';
import KOLCompliant from './compliant';
import pdftest from './pdftest';

import Login from './Login/Login';
import Signup from './Login/Signup';
import Forgotpassword from './Login/Forgotpass';
import Resetpassword from './Login/Resetpassword';

import ClientViewBio from './Client/ClientViewBio';
import ClientViewProfile from './Client/ClientViewProfile';

import PClientViewKolList from './PClient/ClientViewKolList';
import PClientKolProfile from './PClient/ClientKolProfile';
import PProfiletypedetails from './Profiletypedetails';

import ClientViewProject from './Client/ClientViewProject';
import ClientViewKolList from './Client/ClientViewKolList';
import ClientKolProfile from './Client/ClientKolProfile';


import EmployeeManagement from './Admin/EmployeeManagement';
import AdminDashboard from './Admin/AdminDashboard';
import FormManagement from './Admin/FormManagement';
import SpecialityCurd from './Admin/SpecialityCurd';
import SubSpecialityCurd from './Admin/SubSpecialityCurd';
import ProcedureTypeCurd from './Admin/ProcedureTypeCurd';
import Rating from './Admin/Rating';
import ManageKolProfile from './Admin/ManageKolProfile';
import ParameterConfiguration from './Admin/ParameterConfiguration';
import financialreport from './Admin/FinancialReport';



import ReviewSignup from './Manager/ReviewSignup';
import ReviewQuote from './Manager/ReviewQuote';


import ImportRowData from './Manager/ImportRowDataFun';
import ImportCompletefun from './Manager/ImportCompletefun';

import ResercherDashboard from './Resercher/ResercherDashboard';
import KolProfileForm from './Resercher/KolProfileForm';
import EditKolProfileForm from './Resercher/EditKolProfileForm';
import ResercheViewKolList from './Resercher/ResercheViewKolList';
import IdentifierForm from './Resercher/IdentifierForm';
import EditIdentifireForm from './Resercher/EditIdentifireForm';
import DuplicateViewKolList from './Resercher/DuplicateViewKolList';


import ManagerDashboard from './Manager/ManagerDashboard';
import UploadDeepProfiles from './Manager/UploadDeepProfiles';
import CreateProject from './Manager/CreateProject';
import UploadPreliminarykol from './Manager/UploadPreliminarykol';
import UploadPersonalinfobiography from './Manager/UploadPersonalinfobiography';


import AssignkoltoProject from './Manager/AssignKoltoproject';
import EditProject from './Manager/EditProject';
import ManagerKolList from './Manager/ManagerKolList';
import ManagerQC from './Manager/ManagerQC';
import AssignKol from './Manager/AssignKol';
import ManagerQCIdentifire from './Manager/ManagerQCIdentifire';
import ManagerClientProjectList from './Manager/ClientProjectList';
import ManagerActivity from './Manager/ManagerActivity';
import IdentificationActivity from './Manager/IdentificationActivity';
import UploadRawData from './Manager/UploadRawData';



import ManagerClientList from './Manager/ClientList';
import GetInTouch from './Manager/GetInTouch';

import EmployeeDashboard from './Employee/EmployeeDashboard';
import EmployeeDetails from './Employee/EmployeeDetails';


import AdministratorViewProject from './Administrator/AdministratorViewProject';
import AdministratorViewKolList from './Administrator/AdministratorViewKolList';
import AdministratorKolProfile from './Administrator/AdministratorKolProfile';
import AdministratorSearchInCetasDb from './Administrator/AdministratorSearchInCetasDb';
import AdministratorViewSearchKolList from './Administrator/AdministratorViewSearchKolList'

import VisitorDashboard from './Visitor/VisitorDashboard';
import VisitorKolProfile from './Visitor/VisitorKolProfile';

import KolProfileDashboard from './KolProfile/KolProfileDashboard';
import FAQs from './KolProfile/FAQs';
// componentDidMount() {
//     const { history } = this.props;

//     window.addEventListener("popstate", () => {
//       history.go(1);
//     });
// };

const Routes = () => (
     
      <BrowserRouter basename="/">

     {/* <BrowserRouter basename="/cetas"> */}
    
    <Switch>
    <Route exact path='/' component={Home}/>
    <Route exact path='/kol identification' component={KOLidentification}/>
    <Route exact path='/kol engagement' component={KOLEngagement}/>
    <Route exact path='/about us' component={About}/>
    <Route exact path='/pricing' component={Pricing}/>
    <Route exact path='/blogs' component={Resources}/>
    <Route exact path='/blog details' component={BlogsDetails}/> 
    {/* <Route exact path='/casestudy' component={CaseStudy}/>
    <Route exact path='/casestudydetails' component={CaseStudyDetails}/> */}
    <Route exact path='/contact us' component={Contact}/>
    <Route exact path='/compliant' component={KOLCompliant}/>

    <Route  path='/pdftest' component={pdftest} />
    
    <Route  path='/user login' component={Login} />
   
    <Route  path='/home' component={Home} />
    <Route  path='/create new account' component={Signup} />

    <Route  path='/reset password' component={Forgotpassword} />
    <Route  path='/updateoc=eynewy5=eypassword' component={Resetpassword} />

    <Route  path='/KOL Directory' component={PClientViewKolList} />
    <Route  path='/Sample Profile' component={PClientKolProfile} />
    {/* <Route  path='/Profiletypedetails' component={PProfiletypedetails} /> */}

    
    <Route  path='/My Dashboard' component={ClientViewProject} />
    <Route  path='/My Database' component={ClientViewKolList} />
    <Route  path='/Bio Export' component={ClientViewBio} />
    <Route  path='/Profile Export' component={ClientViewProfile} />
    <Route  path='/Profile View' component={ClientKolProfile} />
    {/*<Route path='/SearchInCetasDb' component={SearchInCetasDb} />
    <Route path='/ClientViewSearchKolList' component={ClientViewSearchKolList} />*/}

    


    <Route  path='/AdminDashboard' component={AdminDashboard} />
    <Route  path='/FinancialReport' component={financialreport} />

    

    <Route  path='/EmployeeManagement' component={EmployeeManagement} />
    {/* <Route  path='/CreateProject' component={CreateProject} /> */}
    {/* <Route  path='/EditProject' component={EditProject} /> */}
    <Route  path='/FormManagement' component={FormManagement} />
    <Route  path='/SpecialityCurd' component={SpecialityCurd} />
    <Route  path='/SubSpecialityCurd' component={SubSpecialityCurd} />
    <Route  path='/ProcedureTypeCurd' component={ProcedureTypeCurd} />
    <Route  path='/Rating' component={Rating} />
    <Route  path='/ManageKolProfile' component={ManageKolProfile} />
    <Route  path='/ManagerClientProjectList' component={ManagerClientProjectList} />
    <Route  path='/ManagerActivity' component={ManagerActivity} />
    <Route  path='/IdentificationActivity' component={IdentificationActivity} />
    <Route path='/UploadRawData' component={UploadRawData}/>

    <Route  path='/ManagerClientList' component={ManagerClientList} />
    <Route  path='/enquiry list' component={GetInTouch} />
   
    



    <Route path='/UploadDeepProfiles' component={UploadDeepProfiles}/>
    <Route path='/UploadPersonalInformationBiography' component={UploadPersonalinfobiography}/>
    
    
    <Route path='/ImportRowData' component={ImportRowData}/>
    <Route path='/ImportCompletefun' component={ImportCompletefun}/>
   
    
    <Route  path='/ReviewSignup' component={ReviewSignup} />
    <Route  path='/ReviewQuote' component={ReviewQuote} />

    <Route  path='/ManagerQCIdentifire' component={ManagerQCIdentifire} />
    <Route  path='/ParameterConfiguration' component={ParameterConfiguration} />
    
    <Route  path='/ResercherDashboard' component={ResercherDashboard} />
    <Route  path='/KolProfileForm' component={KolProfileForm} />
    <Route  path='/EditKolProfileForm' component={EditKolProfileForm} />
    <Route  path='/ResercheViewKolList' component={ResercheViewKolList} />
    <Route  path='/IdentifierForm' component={IdentifierForm} />
    <Route  path='/EditIdentifireForm' component={EditIdentifireForm} />
    <Route  path='/DuplicateViewKolList' component={DuplicateViewKolList} />
    

    <Route  path='/ManagerDashboard' component={ManagerDashboard} />
    <Route  path='/CreateProject' component={CreateProject} />
    <Route  path='/UploadPreliminarykol' component={UploadPreliminarykol} />
    <Route path='/AssignkoltoProject' component={AssignkoltoProject}/>
    
    <Route  path='/EditProject' component={EditProject} />
    <Route  path='/ManagerKolList' component={ManagerKolList} />
    <Route  path='/ManagerQC' component={ManagerQC} />
    <Route  path='/AssignKol' component={AssignKol} />
   
    <Route  path='/EmployeeDashboard' component={EmployeeDashboard} />
    <Route  path='/EmployeeDetails' component={EmployeeDetails} />
    
    <Route  path='/AdministratorViewProject' component={AdministratorViewProject} />
    <Route  path='/AdministratorViewKolList' component={AdministratorViewKolList} />
    <Route  path='/AdministratorKolProfile' component={AdministratorKolProfile} />
    <Route path='/AdministratorSearchInCetasDb' component={AdministratorSearchInCetasDb} />
    <Route path='/AdministratorViewSearchKolList' component={AdministratorViewSearchKolList} />

    <Route path='/VisitorDashboard' component={VisitorDashboard} />
    <Route path='/VisitorKolProfile' component={VisitorKolProfile} />

    <Route  path='/KolProfileDashboard' component={KolProfileDashboard} />
    <Route  path='/FAQs' component={FAQs} />
    

    </Switch>
    </BrowserRouter>
   
);
export default Routes;